<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Receive Items
        </v-btn>
      </template>
      <!-- dialog header -->
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="indigo">
            <v-toolbar-title>Receive Item Form</v-toolbar-title>
            <v-spacer />
            <v-btn
              fab
              class="ma-1 pa-0"
              small
              color="red darken-3 white--text"
              @click="(dialog = false), clearobj(index), reset()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- dialog body -->
          <v-snackbar
            style="margin-top: 40px"
            v-model="snackbar"
            color="red accent-2"
            :bottom="y === 'bottom'"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
            v-if="getExaMRexeivedItemsMess.status == 'error'"
          >
            {{ getExaMRexeivedItemsMess.message }}
            <v-btn color="light" flat @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-snackbar>

          <v-snackbar
            style="margin-top: 40px"
            v-model="snackbar"
            color="green darken-3"
            :bottom="y === 'bottom'"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
            v-if="getExaMRexeivedItemsMess.status == 'success'"
          >
            {{ getExaMRexeivedItemsMess.message }}
            <v-btn color="light" flat @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- Item Receiver from -->
          <v-container fluid grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <div class="dark--text">
                  <div id="input_area">
                    <template>
                      <v-layout>
                        <v-flex xs12 md4>
                          <v-text-field
                            v-model="itemListObject.received_no"
                            :rules="RcRules"
                            label="Receive No"
                          ></v-text-field>
                          <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_no == ''"
                            >{{rcval}}</span
                          > -->
                        </v-flex>

                        <v-flex xs12 md4>
                          <v-text-field
                            v-model="itemListObject.mrr_no"
                            :rules="MrrRules"
                            label="MRR No"
                            type="number"
                            required
                          ></v-text-field>
                          <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.mrr_no === ''"
                            >{{mrrval}}</span
                          > -->
                        </v-flex>

                        <v-flex xs12 md4>
                          <v-text-field
                            v-model="itemListObject.received_date"
                            :rules="DateRules"
                            label="Date"
                            type="date"
                            required
                          ></v-text-field>
                          <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_date === ''"
                            >{{dateval}}</span
                          > -->
                        </v-flex>
                      </v-layout>
                    </template>
                    <template>
                      <v-layout>
                        <v-flex xs12 md6>
                          <v-text-field
                            v-model="itemListObject.received_by"
                            :rules="RcByRules"
                            label="Received By"
                            required
                          ></v-text-field>
                          <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_by === ''"
                            >{{receiverval}}</span
                          > -->
                        </v-flex>

                        <v-flex xs12 md6>
                          <v-text-field
                            v-model="itemListObject.remarks"
                            :rules="RemarkRules"
                            label="Remarks"
                            required
                          ></v-text-field>
                          <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.remarks === ''"
                            >{{remarksval}}</span
                          > -->
                        </v-flex>
                      </v-layout>
                    </template>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <!-- Item Receive from -->
          <div id="input_area">
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-toolbar>
                  <v-toolbar-title>Receive Items</v-toolbar-title>
                </v-toolbar>
                <v-card
                  color="grey lighten-5"
                  class="scroll dark--text"
                  height="260px"
                >
                  <template>
                    <v-container fluid>
                      <v-layout
                        row
                        v-for="(items, index) in itemListObject.item_details"
                        :key="index"
                      >
                        <v-flex style="margin-right: 20px">
                          <h3 id="sl_number">{{ index + 1 }}</h3>
                        </v-flex>
                        <v-flex xs12 md4 style="margin-right: 20px">
                          <v-select
                            :items="getExamStoreItemListData"
                            label="Item Name*"
                            item-text="item_name"
                            item-value="id"
                            v-model="items.item_id"
                            :rules="itemRules"
                            required
                          ></v-select>
                        </v-flex>

                        <v-flex xs12 md4 style="margin-right: 20px">
                          <v-select
                            :items="getExamStoreUnitList"
                            label="Unit In*"
                            item-text="unit_name"
                            item-value="id"
                            v-model="items.unit_id"
                            :rules="unitRules"
                            required
                          ></v-select>
                        </v-flex>

                        <v-flex xs12 md4 style="margin-right: 20px">
                          <v-text-field
                            v-model="items.received_quantity"
                            :rules="quantityRules"
                            label="Quantity"
                            type="number"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 md4 style="margin-right: 20px">
                          <v-text-field
                            v-model="items.remarks"
                            :rules="RemarkitemRules"
                            label="Remarks"
                            required
                          ></v-text-field>
                          <!-- <span id="validet_mes" v-if="items.remarks === ''"
                            >Remarks is requred</span
                          > -->
                        </v-flex>

                        <v-flex id="delete_btn">
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="red darken-3 white--text"
                            v-if="index == 0"
                            disabled
                            @click="removeRow(index)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="red darken-3 white--text"
                            v-if="index !== 0"
                            @click="removeRow(index)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </template>
                  <v-card-actions>
                    <v-btn
                      fab
                      class="ma-1 pa-0"
                      small
                      color="indigo darken-3 white--text"
                      @click="addRow()"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    id="button_rcv"
                    color="indigo white--text"
                    text
                    @click="
                      (dialog = true),
                        validate(),
                        saveReceive(itemListObject)
                    "
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      RcRules: [(v) => !!v || "Receive No is is required"],
      MrrRules: [(v) => !!v || "MRR no is required"],
      DateRules: [(v) => !!v || "Receive Date no is required"],
      RcByRules: [(v) => !!v || "Receive person name is required"],
      RemarkRules: [(v) => !!v || "Remarks is required"],
      itemRules: [(v) => !!v || "Select a item is required"],
      unitRules: [(v) => !!v || "Select unit is required"],
      quantityRules: [(v) => !!v || "Item Quantity is required"],
      RemarkitemRules: [(v) => !!v || "Remarks is required"],
      snackbar: false,
      y: "top",
      x: "right",
      mode: "",
      timeout: 6000,
      dialog: false,
      valid: false,
      firstname: "",
      lastname: "",
      itemListObject: {
        received_no: "",
        mrr_no: "",
        received_date: "",
        received_by: "",
        remarks: "",
        item_details: [{}],
      },
    };
  },
  created() {
    // this.addRow();
    this.fatchExamStoreItemList();
    this.fetchExamStoreUnitList();
  },
  computed: {
    getExamStoreItemListData() {
      return this.$store.getters.getExamStoreItemListData
        ? this.$store.getters.getExamStoreItemListData
        : [];
    },
    getExamStoreUnitList() {
      return this.$store.getters.getExamStoreItemUnitList
        ? this.$store.getters.getExamStoreItemUnitList
        : [];
    },
    getExaMRexeivedItemsMess() {
      return this.$store.getters.getExaMRexeivedItemsMess
        ? this.$store.getters.getExaMRexeivedItemsMess
        : [];
    },
  },
  methods: {
    //seve receive item
    saveReceive(itemListObject) {
      console.log(itemListObject, "itemListObject");
      if (
        itemListObject.received_no &&
        itemListObject.mrr_no &&
        itemListObject.received_date &&
        itemListObject.received_by &&
        itemListObject.remarks
      ) {
        console.log("Paylode", itemListObject);
        this.$store
          .dispatch("postExamStoreReceivedItemListItem", itemListObject)
          .then(() => {
            this.$store.dispatch("fetchExamStoreReceivedItemList").then(() => {
              this.snackbar = true;
            });
          });
        console.log("Tanvir Post Request Success!!");
      } else {
        console.log("Sorry!! Tanvir. Post Request not Success!!");
      }
    },
    addRow() {
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        received_quantity: "",
        remarks: "",
      });
    },
    removeRow(index) {
      if (this.itemListObject.item_details.length > 1) {
        this.itemListObject.item_details.splice(index, 1);
      }
    },
    clearobj(index) {
      this.itemListObject = {
        item_details: [],
      };
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        received_quantity: "",
        remarks: "",
      });
    },
    //Reset validation message
    reset() {
      this.$refs.form.reset();
    },
    //Requist validation message
    validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
    },
    //get Exam Store Item List
    fatchExamStoreItemList() {
      this.$store.dispatch("fetchExamStoreItemList");
    },
    //get Exam Store Item unit list
    fetchExamStoreUnitList() {
      this.$store.dispatch("fetchEditExamStoreItemsUnit");
    },
  },
};
</script>

<style>
#input_area {
  padding-left: 50px;
  padding-right: 50px;
}
.scroll {
  overflow-y: scroll;
}
#sl_number {
  margin-top: 20px;
}
#delete_btn {
  margin-top: 5px;
}
#validet_mes {
  color: brown;
}
#button_rcv {
  margin-top: 20px;
}
</style>
