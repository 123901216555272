<template>
  <v-container fluid>
    <v-expansion-panel class="mb-3" inset v-model="custP">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout column wrap>
          <v-flex class="mx-3">
            <v-layout>
              <!-- {{getLoggedInExam}} -->
              <v-flex xs12>
                <v-row>
                  <template>
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout>
                          <v-flex xs12 md4 style="display: flex">
                            <v-icon style="margin-right: 10px">info</v-icon>
                            <v-text-field
                              v-model="searchPeram.mrr_no"
                              label="MRR NO"
                              required
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-select
                              :items="getExamStoreItemListData"
                              label="Select Item"
                              item-text="item_name"
                              item-value="item_code"
                              v-model="searchPeram.item_code"
                              prepend-icon="list"
                              required
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-select
                              :items="itemStatusList"
                              label="Select Status"
                              item-text="value"
                              item-value="id"
                              v-model="searchPeram.item_status"
                              prepend-icon="list"
                              required
                            ></v-select>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12 md4>
                            <v-menu
                              class="ma-2"
                              v-model="menu1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  class="mt-4"
                                  v-model="searchPeram.Fromdate"
                                  label="From Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="searchPeram.Fromdate"
                                @input="(menu1 = false), setDate()"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-menu
                              class="ma-2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  class="mt-4"
                                  v-model="searchPeram.Todate"
                                  label="To Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="searchPeram.Todate"
                                @input="(menu2 = false), setDate()"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </template>
                </v-row>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-flex xs12 sm12 md12 class="my-3">
              <v-layout justify-center row fill-height align-center>
                <v-btn outline round large @click="searchData()">
                  <v-icon left dark>search</v-icon>Search
                </v-btn>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid>
      <div id="main_contaont">
        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-badge left overlap color="transparent">
                <template v-slot:badge>
                  <v-btn
                    fab
                    color="primary"
                    small
                    style="margin-top: -0.8em"
                    class="elevation-0"
                    >{{ getExaMRexeivedItemsList.length }}</v-btn
                  >
                </template>
                <v-card-text class="title">Received List</v-card-text>
              </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <v-card-actions>
              <!-- <v-btn color="primary">Receive</v-btn> -->
              <receiveForm />
            </v-card-actions>
          </v-layout>
        </v-card>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="getExaMRexeivedItemsList"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.received_by }}</td>
              <td>{{ props.item.mrr_no }}</td>
              <td>{{ props.item.received_no }}</td>
              <td>{{ props.item.received_date }}</td>
              <td>{{ props.item.remarks }}</td>
              <td align="center">
                <template>
                  <!-- <v-row justify="center"> -->
                  <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-1 pa-0"
                        color="indigo white--text"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="getreceiveditem(props.item.item_details)"
                      >
                        <v-icon dark>visibility</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar>
                        <v-badge left overlap>
                          <v-card-text class="title">Received Item</v-card-text>
                        </v-badge>
                        <v-spacer />
                        <v-card-actions>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="indigo white--text"
                            @click="dialog = false"
                          >
                            X
                          </v-btn>
                        </v-card-actions>
                      </v-toolbar>
                      <v-card-text>
                        <!-- <v-row> -->
                        <template>
                          <div id="rcitemList">
                            <table>
                              <thead>
                                <tr>
                                  <th id="RCitem">SL</th>
                                  <th id="RCitem">Item Name</th>
                                  <th id="RCitem">Item Code</th>
                                  <th id="RCitem">Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(rcitem, index) in getitemData"
                                  :key="index"
                                >
                                  <td id="RCitem">
                                    {{ index + 1 }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_name
                                        ? rcitem.item.item_name
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_code
                                        ? rcitem.item.item_code
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.remarks
                                        ? rcitem.remarks
                                        : "Not found"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import receiveForm from "./receiveForm.vue";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "@/mixin/common_getters_mixin";

export default {
  components: {
    receiveForm,
  },
  mixins: [commonGet, commonExamYear],
  data() {
    return {
      headers: [
        {
          text: "SL",
          value: "",
        },
        {
          text: "Received By",
          value: "received_by",
        },
        {
          text: "MRR No",
          value: "mrr_no",
        },
        {
          text: "Receive No",
          value: "received_no",
        },
        {
          text: "Received Date",
          value: "received_date",
        },
        {
          text: "Remarks",
          value: "remarks",
        },
        {
          text: "Action",
          value: "",
        },
      ],
      itemStatusList: [
        {
          id: 1,
          value: "Active",
        },
        {
          id: 0,
          value: "Inactive",
        },
      ],
      searchPeram: {},
      loading: false,
      dialog: false,
      updateItemListtada: [],
      itemBalance: "",
      menu1: false,
      menu2: false,
      date: "",
    };
  },

  created() {
    this.getExamStoreReceivedItemList();
  },

  computed: {
    getExaMRexeivedItemsList() {
      return this.$store.getters.getExaMRexeivedItemsList
        ? this.$store.getters.getExaMRexeivedItemsList
        : [];
    },

    getitemData() {
      return this.updateItemListtada;
    },

    getExamStoreItemListData() {
      return this.$store.getters.getExamStoreItemListData
        ? this.$store.getters.getExamStoreItemListData
        : [];
    },
  },

  watch: {
    getExaMRexeivedItemsList(cv) {
      console.log("Item Received List", cv);
    },
  },

  methods: {
    setDate() {
      if (this.searchPeram.Fromdate && !this.searchPeram.Todate) {
        this.searchPeram.Todate = this.searchPeram.Fromdate;
        console.log("This is Fromdate tanvir", this.searchPeram.Todate);
      } else if (this.searchPeram.Todate && !this.searchPeram.Fromdate) {
        this.searchPeram.Fromdate = this.searchPeram.Todate;
        console.log("This is Todate tanvir", this.searchPeram.Fromdate);
      }
    },
    searchData() {
      this.searchPeram.exam_initiate_id = this.getExamInitiateId;
      this.searchPeram.exam_code = this.getLoggedInExam.code;
      this.searchPeram.year = this.getLoggedInYear.year;
      console.log("This is search data", this.searchPeram);
    },
    getExamStoreReceivedItemList() {
      this.loading = true;
      this.$store.dispatch("fetchExamStoreReceivedItemList").then(() => {
        this.loading = false;
      });
    },

    async getreceiveditem(tada) {
      console.log("Item Details", tada);
      for (let i = 0; i < tada.length; i++) {
        this.itemBalance = tada[i].received_quantity;
        console.log("Hello", this.itemBalance);
      }
      this.updateItemListtada = await tada;
      console.log(tada);
    },
  },
};
</script>

<style>
#rcitemList {
  text-align: center;
  margin-left: 18%;
}
#RCitem {
  font-size: 16px;
}
</style>
